
import { Vue, Component } from "vue-property-decorator";
import useGlobalSetting from "@/composables/use-global-setting";
import OrganizationEditor from "@/components/OrganizationEditor.vue";
import { get } from "@/store/pathify-helper";
@Component({ components: { OrganizationEditor } })
export default class GlobalSetting extends Vue {
  setup(props: any, context: any) {
    const user = get(context, "auth/user");
    const {
      globalEngine,
      fetchGlobalUrgentConfig,
      updateGlobalUrgentConfig,
      onChangeGlobalEngine,
      isUrgentChanged
    } = useGlobalSetting();
    return {
      user,

      // useGlobalSetting
      globalEngine,
      fetchGlobalUrgentConfig,
      updateGlobalUrgentConfig,
      onChangeGlobalEngine,
      isUrgentChanged
    };
  }
}
