import AdminService, { ServiceEnginePayload } from "@/services/admin.service";
import OrganizationService, {
  Organization
} from "@/services/organization.service";
import { onMounted, reactive, toRefs } from "@vue/composition-api";

interface UseOrganizationConfigState {
  loading: boolean;
  organization: Organization;
  serviceEngine: ServiceEnginePayload;
  showServiceEngine: boolean;
}
const useOrganizationConfig = () => {
  const state = reactive<UseOrganizationConfigState>({
    loading: true,
    organization: {},
    serviceEngine: {
      twilioAccountSid: "",
      twilioApiKeySid: "",
      twilioApiKeySecret: "",
      tokboxApiKey: "",
      tokboxApiSecret: "",
      awsTokboxAccessKeyId: "",
      awsTokboxSecretAccessKey: ""
    },
    showServiceEngine: false
  });

  const fetchOrganization = async () => {
    state.loading = true;
    const data = await OrganizationService.getOrganization();
    state.organization = data;
    const { serviceEngine } = state.organization;
    if (serviceEngine?.tokbox && serviceEngine?.twilio) {
      state.serviceEngine.awsTokboxAccessKeyId =
        serviceEngine.tokbox.aws.accessKeyId;
      state.serviceEngine.awsTokboxSecretAccessKey =
        serviceEngine.tokbox.aws.secretAccessKey;
      state.serviceEngine.tokboxApiKey = serviceEngine.tokbox.apiKey;
      state.serviceEngine.tokboxApiSecret = serviceEngine.tokbox.apiSecret;
      state.serviceEngine.twilioAccountSid = serviceEngine.twilio.accountSid;
      state.serviceEngine.twilioApiKeySecret =
        serviceEngine.twilio.apiKeySecret;
      state.serviceEngine.twilioApiKeySid = serviceEngine.twilio.apiKeySid;
    }
    state.loading = false;
  };

  const updateServiceEngine = async () => {
    if (state.organization.id) {
      await AdminService.updateServiceEngine(
        state.organization.id,
        state.serviceEngine
      );
    }
  };

  const toggleShowServiceEngine = () => {
    state.showServiceEngine = !state.showServiceEngine;
  };
  onMounted(() => {
    fetchOrganization();
  });
  return {
    ...toRefs(state),
    updateServiceEngine,
    toggleShowServiceEngine
  };
};

export default useOrganizationConfig;
