import AdminService, { GlobalUrgentEngineEnum } from "@/services/admin.service";
import { onMounted, reactive, toRefs } from "@vue/composition-api";

interface UseOrganizationConfigState {
  globalEngine: GlobalUrgentEngineEnum;
  isUrgentChanged: boolean;
}
const useGlobalSetting = () => {
  const state = reactive<UseOrganizationConfigState>({
    globalEngine: GlobalUrgentEngineEnum.DEFAULT,
    isUrgentChanged: false
  });

  const fetchGlobalUrgentConfig = async () => {
    state.isUrgentChanged = false;
    const value = await AdminService.getUrgentEngine();
    state.globalEngine = value;
  };

  const onChangeGlobalEngine = () => {
    state.isUrgentChanged = true;
  };

  const updateGlobalUrgentConfig = async () => {
    await AdminService.changeUrgentEngine(state.globalEngine);
    await fetchGlobalUrgentConfig();
  };

  onMounted(() => {
    fetchGlobalUrgentConfig();
  });
  return {
    ...toRefs(state),
    fetchGlobalUrgentConfig,
    updateGlobalUrgentConfig,
    onChangeGlobalEngine
  };
};

export default useGlobalSetting;
