
import { Vue, Component } from "vue-property-decorator";
import useOrganizationConfig from "@/composables/use-organization-config";
import OrganizationEditor from "@/components/OrganizationEditor.vue";
import { get } from "@/store/pathify-helper";
@Component({ components: { OrganizationEditor } })
export default class Organization extends Vue {
  setup(props: any, context: any) {
    const user = get(context, "auth/user");
    const {
      loading,
      organization,
      serviceEngine,
      updateServiceEngine,
      showServiceEngine,
      toggleShowServiceEngine
    } = useOrganizationConfig();
    return {
      user,

      // useOrganizationConfig
      loading,
      organization,
      serviceEngine,
      updateServiceEngine,
      showServiceEngine,
      toggleShowServiceEngine
    };
  }
}
